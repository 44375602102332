import './firebase'
import './index.css'

import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'

import router from './router'
import { getAuth, onAuthStateChanged } from 'firebase/auth'

let vueApp
const auth = getAuth()

onAuthStateChanged(auth, async () => {
  if (!vueApp) {
    vueApp = createApp(App)
      .use(router)
      .mount('#app')
  }
})
