<template>
  <div class="home">
    <component v-if="signedIn" :is="config.currentView" :config="config" />
    <div class="signInBox" v-else>
      <a class="pax-button" @click="signIn()">Log In</a>
    </div>
  </div>
  <!-- <div v-if="signedIn">
   <button @click="signOut()">Sign out!</button>
  </div> -->
</template>

<script>
// @ is an alias to /src
import { firebaseSignOut, firebaseSignIn } from '@/services/FirebaseAuthService'
import {
  getAuth,
  onAuthStateChanged
} from 'firebase/auth'
import { db } from '@/firebase'
import { collection, getDocs, doc, onSnapshot } from '@firebase/firestore'
import home from '@/components/Home.vue'
import summary from '@/components/Summary.vue'
import team from '@/components/Team.vue'

export default {
  name: 'HomeView',
  components: {
    home,
    summary,
    team
  },
  data () {
    return {
      config: {},
      signedIn: false
    }
  },
  methods: {
    signOut () {
      firebaseSignOut()
      this.signedIn = false
    },
    async signIn () {
      const user = await firebaseSignIn()
      this.signedIn = !!(user)
    }
  },
  async mounted () {
    /* eslint-ignore-next-line */
    const unsub = onSnapshot(doc(db, 'config', 'config'), (doc) => {
      this.config = doc.data()
    })

    const auth = getAuth()

    onAuthStateChanged(auth, (user) => {
      console.log('auth state changed')
      if (user) {
        // User is signed in, see docs for a list of available properties
        // https://firebase.google.com/docs/reference/js/firebase.User
        const uid = user.uid
        this.signedIn = true
        // ...
      } else {
        console.log('not signed in')
        this.$router.push({ path: '/' })
        // this.$router.push('/about')
        // User is signed out
      }
    })

    // const docSnap = await getDocs(collection(db, 'teams'))
    // this.teams = docSnap.docs.map(d => d.data().name)
  }
}
</script>

<style>
.home {
  height: 100vh;
  width: 100vw;
  background-image: url('@/assets/mobile-home.png');
  background-size: cover;
  background-position: bottom;
}
h1 {
  color: #fff;
}
.signInBox {
  background-color: #121619;
  position: fixed;
  width: 100vw;
  height: 16vh;
  bottom: 0;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
.signInBox .pax-button {
  height: 20px;
  text-transform: uppercase;
  padding: 8px 32px;
}
</style>
