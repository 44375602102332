/* eslint-disable */
import {
  db
} from '../firebase'
import {
  doc,
  updateDoc,
  setDoc,
  query,
  onSnapshot,
  collection,
  getDoc,
  getDocs
} from 'firebase/firestore'

const defaultTeam = {
  teamId: 0,
  name: '',
  description: '',
  url: '',
  members: [],
  businessImpact: 0,
  engineeringImpact: 0,
  completion: 0,
  creativity: 0,
  aggregate: 0,
  presentation: 0
}

function calculate_score_after_vote(
  businessImpact,
  engineeringImpact,
  completion,
  creativity,
  aggregate,
  presentation) {
  teamScores = team_get_scores(teamId)

}

// CRUD STUFF

/**
 * 
 * @param teamId the teamId of the team
 * @returns Object of all fields
 */
export const team_get = async (teamId) => {
  try {
    const docRef = doc(db, 'teams', teamId)
    const docSnap = await getDoc(docRef)

    return docSnap.exists() ? docSnap.data() : {}
  } catch (e) {
    throw new Error(e)
  }
}

/**
 * 
 * @param teamId of team
 * @returns businessImpact, engineeringImpact, completion, creativity, aggregate
 */
export const team_get_scores = async (teamId) => {
  try {
    const docRef = doc(db, 'teams', teamId)
    const team = await getDoc(docRef)

    if (team.exists()) {
      return {
        businessImpact: team.business - impact,
        engineeringImpact: team.engineering - impact,
        completion: team.completion,
        creativity: team.creativity,
        aggregate: team.aggregate,
        presentation: team.presentation
      }
    } else {
      throw new Error("Document does not exist")
    }
  } catch (e) {
    throw new Error(e)
  }
}

export const resetTeams = (teams) => {
  if (!teams.length) return
  const cleanData = {
    aggregate: 0,
    businessImpact: 0,
    completion: 0,
    creativity: 0,
    engineeringImpact: 0,
    presentation: 0
  }
  const promises = []
  teams.forEach(t => {
    promises.push(updateDoc(doc(db, `teams/${t.teamId}`), cleanData))
  })
  return Promise.all(promises)
}

export const getHighestScoringTeam = async () => {
  try {
    const teamsRef = collection(db, 'teams')
    const teams = await getDocs(teamsRef)
    let highestTeam
    let highestScore = 0
    teams.forEach((doc) => {
      const currentScore = doc.data().aggregate
      if (currentScore > highestScore) {
        highestScore = currentScore
        highestTeam = doc.data()
      }
    })
    return highestTeam
  } catch (e) {
    throw new Error(e)
  }
}

export const getAllTeams = async() => {
    try {
        const teamsRef = collection(db, "teams")
        const teams = await getDocs(teamsRef)
        let teamsData = teams.docs.map(d => d.data())
        return teamsData
    } catch (e) {
        throw new Error(e)
    }
}

export const get_team_aggregate = async(teamId) => {
    try {
        const docRef = doc(db, 'teams', teamId)
        const team = await getDoc(docRef).data()
        return team.aggregate

    } catch (e) {
        throw new Error(e)
    }
}

// export const update_team_scores = async(
//     teamId,
//     businessImpact,
//     engineeringImpact,
//     completion,
//     creativity,
//     aggregate
// ) => {

//     const team = doc(db, 'teams', teamId)
//     try {
//         await updateDoc (
//             team, {
//                 [business-impact]: businessImpact,
//                 [engineering-impact]: engineeringImpact,
//                 completion: completion,
//                 creativity: creativity,
//                 aggregate: aggregate,
//                 presentation: presentation
//             })
//     } catch (e) {
//         throw new Error(e)
//     }
// }
