<template>
  <div class="voting">
    <div v-if="hasVoted">
      <h2>Voting Complete!</h2>
      <p>Get ready to vote on the next project!</p>
    </div>
    <div v-else>
      <h3>Business Impact</h3>
      <div class="wrapper">
        <div :class="{ vote: true, isActive: voteData.businessImpact === 1}" @click="voteData.businessImpact = 1"><img class="voteIcon" src="@/assets/vote-1.svg" /></div>
        <div :class="{ vote: true, isActive: voteData.businessImpact === 2}" @click="voteData.businessImpact = 2"><img class="voteIcon" src="@/assets/vote-2.svg" /></div>
        <div :class="{ vote: true, isActive: voteData.businessImpact === 3}" @click="voteData.businessImpact = 3"><img class="voteIcon" src="@/assets/vote-3.svg" /></div>
        <div :class="{ vote: true, isActive: voteData.businessImpact === 4}" @click="voteData.businessImpact = 4"><img class="voteIcon" src="@/assets/vote-4.svg" /></div>
        <div :class="{ vote: true, isActive: voteData.businessImpact === 5}" @click="voteData.businessImpact = 5"><img class="voteIcon" src="@/assets/vote-5.svg" /></div>
      </div>

      <h3>Engineering Impact</h3>
      <div class="wrapper">
        <div :class="{ vote: true, isActive: voteData.engineeringImpact === 1}" @click="voteData.engineeringImpact = 1"><img class="voteIcon" src="@/assets/vote-1.svg" /></div>
        <div :class="{ vote: true, isActive: voteData.engineeringImpact === 2}" @click="voteData.engineeringImpact = 2"><img class="voteIcon" src="@/assets/vote-2.svg" /></div>
        <div :class="{ vote: true, isActive: voteData.engineeringImpact === 3}" @click="voteData.engineeringImpact = 3"><img class="voteIcon" src="@/assets/vote-3.svg" /></div>
        <div :class="{ vote: true, isActive: voteData.engineeringImpact === 4}" @click="voteData.engineeringImpact = 4"><img class="voteIcon" src="@/assets/vote-4.svg" /></div>
        <div :class="{ vote: true, isActive: voteData.engineeringImpact === 5}" @click="voteData.engineeringImpact = 5"><img class="voteIcon" src="@/assets/vote-5.svg" /></div>
      </div>

      <h3>Creativity</h3>
      <div class="wrapper">
        <div :class="{ vote: true, isActive: voteData.creativity === 1}" @click="voteData.creativity = 1"><img class="voteIcon" src="@/assets/vote-1.svg" /></div>
        <div :class="{ vote: true, isActive: voteData.creativity === 2}" @click="voteData.creativity = 2"><img class="voteIcon" src="@/assets/vote-2.svg" /></div>
        <div :class="{ vote: true, isActive: voteData.creativity === 3}" @click="voteData.creativity = 3"><img class="voteIcon" src="@/assets/vote-3.svg" /></div>
        <div :class="{ vote: true, isActive: voteData.creativity === 4}" @click="voteData.creativity = 4"><img class="voteIcon" src="@/assets/vote-4.svg" /></div>
        <div :class="{ vote: true, isActive: voteData.creativity === 5}" @click="voteData.creativity = 5"><img class="voteIcon" src="@/assets/vote-5.svg" /></div>
      </div>

      <h3>Completion</h3>
      <div class="wrapper">
        <div :class="{ vote: true, isActive: voteData.completion === 1}" @click="voteData.completion = 1"><img class="voteIcon" src="@/assets/vote-1.svg" /></div>
        <div :class="{ vote: true, isActive: voteData.completion === 2}" @click="voteData.completion = 2"><img class="voteIcon" src="@/assets/vote-2.svg" /></div>
        <div :class="{ vote: true, isActive: voteData.completion === 3}" @click="voteData.completion = 3"><img class="voteIcon" src="@/assets/vote-3.svg" /></div>
        <div :class="{ vote: true, isActive: voteData.completion === 4}" @click="voteData.completion = 4"><img class="voteIcon" src="@/assets/vote-4.svg" /></div>
        <div :class="{ vote: true, isActive: voteData.completion === 5}" @click="voteData.completion = 5"><img class="voteIcon" src="@/assets/vote-5.svg" /></div>
      </div>

      <h3>Presentation</h3>
      <div class="wrapper">
        <div :class="{ vote: true, isActive: voteData.presentation === 1}" @click="voteData.presentation = 1"><img class="voteIcon" src="@/assets/vote-1.svg" /></div>
        <div :class="{ vote: true, isActive: voteData.presentation === 2}" @click="voteData.presentation = 2"><img class="voteIcon" src="@/assets/vote-2.svg" /></div>
        <div :class="{ vote: true, isActive: voteData.presentation === 3}" @click="voteData.presentation = 3"><img class="voteIcon" src="@/assets/vote-3.svg" /></div>
        <div :class="{ vote: true, isActive: voteData.presentation === 4}" @click="voteData.presentation = 4"><img class="voteIcon" src="@/assets/vote-4.svg" /></div>
        <div :class="{ vote: true, isActive: voteData.presentation === 5}" @click="voteData.presentation = 5"><img class="voteIcon" src="@/assets/vote-5.svg" /></div>
      </div>
      <div class="submit-vote">
        <a @click="vote" class="pax-button submit-vote">Submit Vote</a>
      </div>
    </div>
  </div>
</template>

<script>
import { getAuthenticatedUser } from '../services/FirebaseAuthService'
import { createVote } from '../services/VoteService'
export default {
  name: 'Voting',
  props: [
    'config'
  ],
  data () {
    return {
      hasVoted: false,
      voteData: {
        businessImpact: 3,
        engineeringImpact: 3,
        creativity: 3,
        completion: 3,
        presentation: 3
      }
    }
  },
  watch: {
    async config (newConfig, oldConfig) {
      if (newConfig.teamId !== oldConfig.teamId) {
        this.hasVoted = false
        this.voteData = {
          businessImpact: 3,
          engineeringImpact: 3,
          creativity: 3,
          completion: 3,
          presentation: 3
        }
      }
    }
  },
  mounted () {
    this.hasVoted = false
    console.log('Setting hasVoted to false...')
  },
  methods: {
    vote () {
      console.log('Hit vote method')
      if (this.hasVoted) {
        return
      }
      // get the user // need function that gets current users user object or uid at the least
      const userId = JSON.parse(localStorage.getItem('user')).uid
      // get the team
      const teamId = this.config.teamId
      // go place a vote!
      const payload = {
        userId: userId,
        teamId: teamId,
        voteId: userId + '-' + teamId,
        businessImpact: this.voteData.businessImpact,
        engineeringImpact: this.voteData.engineeringImpact,
        creativity: this.voteData.creativity,
        completion: this.voteData.completion,
        presentation: this.voteData.presentation
      }
      createVote(payload)
      this.hasVoted = true
    }
  }
}
</script>

<style>
.voting {
  color: #fff;
}
.voting .wrapper {
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.submit-vote {
  margin-top: 40px;
}
.voting .vote {
  margin: 0 8px;
}
.voting .vote.isActive {
  border-bottom: 2px dashed #0f0;
}
.voting .voteIcon {
  width: 32px;
  height: 32px;
}
</style>
