import { db } from '../firebase'
import { ref } from 'vue'
import { doc, setDoc } from 'firebase/firestore'
import {
  getAuth,
  onAuthStateChanged,
  signInWithPopup,
  GoogleAuthProvider,
  signOut
} from 'firebase/auth'

const provider = new GoogleAuthProvider()
const auth = getAuth()

const emptyUser = { uid: null }

export const getAuthenticatedUser = () => {
  const authUser = ref(emptyUser)

  onAuthStateChanged(auth, (user) => {
    authUser.value = user || emptyUser
  })

  return authUser
}

onAuthStateChanged(auth, (user) => {
  console.log('auth state changed in service')
  if (user) {
    // User is signed in, see docs for a list of available properties
    // https://firebase.google.com/docs/reference/js/firebase.User
    const uid = user.uid
    localStorage.setItem('user', JSON.stringify({ uid: user.uid, email: user.email }))
    // ...
  } else {
    console.log('user signed out')

    // User is signed out
    // ...
  }
})

export function firebaseSignIn () {
  console.log('login')

  signInWithPopup(auth, provider)
    .then((result) => {
      // The signed-in user info.
      const user = result.user
      let adminValue = false
      if (user.email === 'aswitzer@paxating.com' || user.email === 'mlocke@paxating.com' || user.email === 'mpineiro@paxating.com' || user.email === 'calexander@paxating.com') {
        adminValue = true
      }
      // console.table(user)

      // Save the user to firestore
      setDoc(doc(db, 'users', user.uid), { uid: user.uid, email: user.email, isAdmin: adminValue })
      localStorage.setItem('user', JSON.stringify(user))
      return user
      // ...
    }).catch((error) => {
      // Handle Errors here.
      const errorCode = error.code
      const errorMessage = error.message
      console.log('error: ' + errorMessage)
      // The email of the user's account used.
      // const email = error.customData.email
      // The AuthCredential type that was used.
      const credential = GoogleAuthProvider.credentialFromError(error)
      // ...
    })
}

export function firebaseSignOut () {
  return signOut(auth).then(() => {
    localStorage.removeItem('user')
    console.log('sign out successful')
  }).catch((e) => {
    console.log('sign out error' + e)
  })
}
