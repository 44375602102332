<template>
<div class="mobile-home">
  <img src="@/assets/logo.svg" alt="logo" />
</div>
</template>

<style>
.mobile-home {
  height: 100vh;
  width: 100vw;
  background-image: url('@/assets/mobile-home.png');
  background-size: cover;
  background-position: center;
}
</style>
