<template>
<div class="summary">
  <img src="@/assets/logo.svg" alt="logo" />
  <h2>The Results Are In!</h2>
  <h1>{{ winningTeam.name }} wins!!!</h1>
  <h3>{{ winningTeam.aggregate }}/100 pts</h3>
</div>
</template>

<script>
import { getHighestScoringTeam } from '@/services/TeamService'

export default {
  data () {
    return {
      winningTeam: {}
    }
  },
  async mounted () {
    this.winningTeam = await getHighestScoringTeam()
  }
}
</script>

<style>
.summary {
  padding: 1rem;
}

.summary h2 {
  font-size: .8rem;
}

.summary h1 {
  font-size: 2rem;
}

.summary h3 {
  margin-top: -30px;
  text-align: left;
}

.summary p {
  color: #fff;
}
</style>
