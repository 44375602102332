// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app'
import { getFirestore } from 'firebase/firestore'
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: 'AIzaSyCJj4KbuVCoe0TNJLlgBk8QtHpSzpdWJ6M',
  authDomain: 'hackathon-system.firebaseapp.com',
  projectId: 'hackathon-system',
  storageBucket: 'hackathon-system.appspot.com',
  messagingSenderId: '413271940321',
  appId: '1:413271940321:web:436556ab84ae40bf9eeba0'
}

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig)

export const db = getFirestore(firebaseApp)
