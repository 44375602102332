<template>
  <div class="mobile-team">
    <div class="text-centered">
      <img src="@/assets/logo.svg" alt="logo" />
    </div>
    <h2>{{ teamData.name }}</h2>
    <voting :config = 'config' />
  </div>
</template>

<script>
import { db } from '@/firebase'
import { collection, getDoc, doc, onSnapshot } from '@firebase/firestore'
import voting from '@/components/Voting.vue'

export default {
  name: 'Team',
  props: [
    'config'
  ],
  components: {
    voting
  },
  data () {
    return {
      teamData: {}
    }
  },
  watch: {
    async config (newConfig, oldConfig) {
      if (newConfig.teamId) {
        console.log('We have an update with teamId')
        const docSnap = await getDoc(doc(db, 'teams', `${newConfig.teamId}`))
        if (docSnap.exists()) {
          this.teamData = docSnap.data()
        } else {
          console.log('No team found with that Id.')
        }
      }
    }
  },
  async mounted () {
    const docSnap = await getDoc(doc(db, 'teams', `${this.config.teamId}`))
    if (docSnap.exists()) {
      this.teamData = docSnap.data()
    } else {
      console.log('No team found with that Id.')
    }
  }
}
</script>

<style>
.mobile-team {
  background-color: #111;
  width: 100vw;
  min-height: 100vh;
  padding: 20px;
  box-sizing: border-box;
  text-align: center;
}
</style>
