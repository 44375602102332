import { db } from '../firebase'
import { doc, updateDoc, setDoc, query, onSnapshot, collection, getDoc, where, getDocs, addDoc } from 'firebase/firestore'

const defaultVote = {
  userId: 0,
  teamId: 0,
  voteId: '0-0',
  businessImpact: 0,
  engineeringImpact: 0,
  creativity: 0,
  completion: 0,
  presentation: 0
}

// CRUD STUFF

/**
 *
 * @param id of user
 * @param teamId team they voted on
 * @returns businessImpact, engineeringImpact, completion, creativity, aggregate
 */
export const getUserVote = async (userId, teamId) => {
  try {
    const docRef = doc(db, 'votes', [userId, teamId])
    return await getDoc(docRef.data())
  } catch (e) {
    throw new Error(e)
  }
}

/**
 *
 * @param vote the vote to add
 * @returns promise resolve if successful
 */
export const createVote = async (vote = defaultVote) => {
  try {
    setDoc(doc(db, 'votes', vote.userId + '-' + vote.teamId), vote)
    // return Promise.resolve()
  } catch (e) {
    throw new Error(e)
  }
}

/**
 *
 * @param teamId
 * @returns list of vote data
 */
export const getTeamVotes = async (teamId) => {
  try {
    const q = query(collection(db, 'votes'), where('teamId', '==', teamId))
    const querySnapshot = await getDocs(q)
    const votes = []
    querySnapshot.forEach((doc) => {
      votes.push(doc.data())
    })
    return votes
  } catch (e) {
    throw new Error(e)
  }
}
