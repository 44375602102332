<template>
  <router-view/>
</template>

<script>
// @ is an alias to /src
import { db } from '@/firebase'
import { collection, getDocs, doc, onSnapshot } from '@firebase/firestore'

export default {
  name: 'HomeView',
  data () {
    return {
      config: {}
    }
  },
  async mounted () {
    /* eslint-ignore-next-line */
    const unsub = onSnapshot(doc(db, 'config', 'config'), (doc) => {
      this.config = doc.data()
    })

    // const docSnap = await getDocs(collection(db, 'teams'))

    // this.teams = docSnap.docs.map(d => d.data().name)
  }
}
</script>

<style>
body {
  margin: 0;
  padding: 0;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.pax-button {
  border: 2px solid #44d62c;
  border-radius: 18px;
  padding: 4px 8px;
  color: #fff;
}
.pax-button:hover {
  cursor: pointer;
  border: 3px solid #44d62c;
}

h1 {
  margin-top: 0;
  font-size: 48px;
}

h2, h3 {
  color: #fff;
}

h3 {
  text-align: center;
}

nav {
  padding: 30px;
}
</style>
